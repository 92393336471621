import React from 'react';
import { Form, withFormik, FastField, ErrorMessage, FormikProps } from 'formik';
import Recaptcha from 'react-google-recaptcha';
import * as Yup from 'yup';
import { Button, Input } from 'components/common';
import { recaptcha_key } from 'data/config';
import { Error, Center, InputField, AlignEnd } from './styles';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';

// Shape of form values
interface FormValues {
  email: string;
  name: string;
  message: string;
  success: boolean;
  recaptcha: string;
}

interface OtherProps {
  intl: any
}

const ContactForm = ({ setFieldValue, isSubmitting, values, errors, touched, intl }: OtherProps & FormikProps<FormValues>) => (
  <Form
    name="portfolio-dev"
    method="post"
    data-netlify="true"
    data-netlify-recaptcha="true"
    data-netlify-honeypot="bot-field"
  >
    <InputField>
      <Input
        as={FastField}
        type="text"
        name="name"
        component="input"
        aria-label="name"
        placeholder={`${intl.formatMessage({ id: "contact.name" })}*`}
        error={touched.name && errors.name}
      />
      <ErrorMessage component={Error} name="name" />
    </InputField>
    <InputField>
      <Input
        id="email"
        aria-label="email"
        component="input"
        as={FastField}
        type="email"
        name="email"
        placeholder={`${intl.formatMessage({ id: "contact.mail" })}*`}
        error={touched.email && errors.email}
      />
      <ErrorMessage component={Error} name="email" />
    </InputField>
    <InputField>
      <Input
        as={FastField}
        component="textarea"
        aria-label="message"
        id="message"
        rows="8"
        type="text"
        name="message"
        placeholder={`${intl.formatMessage({ id: "contact.message" })}*`}
        error={touched.message && errors.message}
      />
      <ErrorMessage component={Error} name="message" />
    </InputField>
    {values.name && values.email && values.message && (
      <InputField>
        <FastField
          component={Recaptcha}
          sitekey={recaptcha_key}
          name="recaptcha"
          onChange={(value: boolean) => setFieldValue('recaptcha', value)}
        />
        <ErrorMessage component={Error} name="recaptcha" />
      </InputField>
    )}
    {values.success && (
      <InputField>
        <Center>
          <h4><FormattedMessage id="contact.success" /></h4>
        </Center>
      </InputField>
    )}
    <AlignEnd style={{ display: 'flex', alignItems: 'flex-end' }}>
      <Button secondary type="submit" disabled={isSubmitting}>
        <FormattedMessage id="contact.send" />
      </Button>
    </AlignEnd>
  </Form>
);

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    email: '',
    message: '',
    recaptcha: '',
    success: false,
  }),
  validationSchema: ({ intl }: { intl: any }) =>
    Yup.object().shape({
      name: Yup.string().required(intl.formatMessage({ id: "contact.name_validation" })),
      email: Yup.string().email(intl.formatMessage({ id: "contact.invalid_email" })).required(intl.formatMessage({ id: "contact.email_validation" })),
      message: Yup.string().required(intl.formatMessage({ id: "contact.message_validation" })),
      recaptcha: Yup.string().required(intl.formatMessage({ id: "contact.invalid_robots" })),
    }),
  handleSubmit: async ({ name, email, message, recaptcha }, { setSubmitting, resetForm, setFieldValue }) => {
    try {
      const encode = (data: any) =>
        Object.keys(data)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join('&');
      await fetch('/?no-cache=1', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'MultiversoID',
          name,
          email,
          message,
          'g-recaptcha-response': recaptcha,
        }),
      });
      setSubmitting(false);
      setFieldValue('success', true);
      setTimeout(() => resetForm(), 2000);
    } catch (err) {
      setSubmitting(false);
      setFieldValue('success', false);
      alert('Something went wrong, please try again!') // eslint-disable-line
    }
  },
})(injectIntl(ContactForm));
