import styled from 'styled-components';
import { Row, Column } from '../../common';
import overlayIllustration from 'assets/images/spaceman-2.png';

export const Wrapper = styled(Row)`
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-image: url(${overlayIllustration});
  height: 100vh;
  min-height: calc(100vh - 140px);

  display: flex;
  color: white;

  @media (max-width: 960px) {
    background-size: 300px;
  }
`;

export const SideColumn = styled(Column)`
  height: 100%;
  display: flex;
  align-items: flex-end;
  border-top: 1px solid #7b7b7b61;

  @media (max-width: 960px) {
    flex-direction: column;
  }

`;
