import React from 'react';
import spaceman from 'assets/icons/id.svg';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Lead, Avatar, H4, H5 } from '../../common';
import { CardWrapper } from './styles';

type TeamProps = {
  name: string;
  role: string;
  bio: string;
};

export const TeamCard = ({ name, role, bio }: TeamProps) => (
  <CardWrapper>
    {/* <Avatar /> */}
    <div>
      <H4>
        <img src={spaceman} alt="Multiverso ID" style={{ marginRight: 8 }} />
        {name}
      </H4>
      <H5>
        <FormattedMessage id={role} />
      </H5>
      <Lead>
        <FormattedMessage id={bio} />
      </Lead>
    </div>
  </CardWrapper>
);
