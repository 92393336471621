import styled from 'styled-components';
import overlayIllustration from 'assets/images/bg.png';
import multiverse from 'assets/icons/brand.svg';
import { Column } from '../../common';



export const Wrapper = styled.div`
  background-size: cover;
  background-position: right top;
  background-repeat: no-repeat;
  background-image: url(${overlayIllustration});
  height: 100vh;


  @media (max-width: 960px) {
    background-position: center bottom;
    background-size: 1153px;
  }
`;

export const BrandWrapper = styled.div`
  background-image: url(${multiverse});
  background-position: -277px center;
  background-size: 1712px;
  background-repeat: no-repeat;
  height: calc(100vh - 140px);

  @media (max-width: 960px) {
    background-position: left 77px;
    background-size: 353px;
  }
`;

export const Details = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 36pt;
    color: #212121;
    max-width: 225px;

    @media (max-width: 680px) {
      font-size: 30pt;
    }
  }

  h4 {
    margin-bottom: 2.5rem;
    font-size: 32pt;
    font-weight: normal;
    color: #707070;

    @media (max-width: 680px) {
      font-size: 26pt;
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
  }

  img {
    width: 100%;
  }
`;

export const YellowRibow = styled.div`
  flex: 1;
  background-color: rgba(254, 211, 29, 0.8);
  height: 160px;
`

export const Title = styled.h1`
  font-size: 30px;
  color: #000;
  margin: 0;
  max-width: 225px;
`

export const TitleContainer = styled.div`
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 60px 0 60px;
  flex: 1;

  p {
    margin: 0;
    padding: 0 60px;
    color: black;
    font-size: 16px;
  }

  @media (max-width: 960px) {
    padding: 32px;
    align-items: center;

    p {
      margin: 0;
      padding: 0;
    }

    div {
      display: none;
    }
  }
`

export const MobileTitle = styled.div`
  display: none;

  @media (max-width: 960px) {
    display: block;
    h1 {
      color: white;
    }
  }

`

export const TextColumn = styled.div`
  flex-direction: column;
`

export const FirstRibbowContainer = styled(Column)`
  border-right: 1px solid #7b7b7b61;
  height: 100%;
  display: flex;
  align-items: flex-end;

  @media (max-width: 960px) {
    display: none;
  }
`;