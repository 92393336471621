import React from 'react'
import { Header } from 'components/theme'
import { Row, Column, SocialLinks } from 'components/common'
import { Wrapper, Title, TitleContainer, YellowRibow, BrandWrapper, MobileTitle, TextColumn, FirstRibbowContainer } from './styles'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { MainBar, Lead } from '../../common'

// description
// multiverse

export const Hero = () => (
	<Wrapper>
		<Header />
		<BrandWrapper>
			<Row style={{ height: 'calc(100vh - 140px)' }}>
				<Column mobile="2" tablet="2" desktop="2" style={{ borderRight: '1px solid #7b7b7b61', height: '100%' }} />

				<MainBar style={{ borderRight: '1px solid #7b7b7b61', height: '100%', display: 'flex', alignItems: 'flex-end' }}>
					<FirstRibbowContainer mobile="12" tablet="12" desktop="4.8">
						<YellowRibow>
							<TitleContainer>
								<Title>
									<FormattedMessage id="description" />
								</Title>
							</TitleContainer>
						</YellowRibow>
					</FirstRibbowContainer>

					<Column mobile="12" tablet="12" desktop="7.2" style={{ height: '100%', display: 'flex', alignItems: 'flex-end', }}>
						<TextColumn>
							<MobileTitle>
								<TitleContainer>
									<Title>
										<FormattedMessage id="description" />
									</Title>
								</TitleContainer>
							</MobileTitle>
							<YellowRibow>
								<TitleContainer>
									<Lead>
										<FormattedMessage id="multiverse" />
									</Lead>
									<div style={{ margin: 0, padding: '0 60px' }}>
										<SocialLinks />
									</div>
								</TitleContainer>
							</YellowRibow>
						</TextColumn>
					</Column>

				</MainBar>
			</Row>
		</BrandWrapper>

	</Wrapper>
)
