import React from 'react';

import { Column, Sidebar, TextBox, MainBar } from '../../common';
import { Wrapper, TeamColumn } from './styles';
import { TeamCard } from './TeamCard';

const TEAM = [
  //   { name: 'Carlos Pessoa', role: 'team1.role', bio: 'team1.bio' },
  //   { name: 'Carlos Pessoa 2', role: 'team1.role', bio: 'team1.bio' },
  //   { name: 'Carlos Pessoa 3', role: 'team1.role', bio: 'team1.bio' },
  //   { name: 'Carlos Pessoa 4', role: 'team1.role', bio: 'team1.bio' },
];

export const Team = () => (
  <Wrapper id="team">
    <Sidebar label="menu.who" />
    <MainBar>
      {/* <Column mobile="10" tablet="10" desktop="10" style={{ display: 'flex', borderTop: '1px solid #7b7b7b61', justifyContent: 'flex-start' }}> */}
      <TextBox reverse title="who.subtitle" subtitle="who.lead" lead="who.description" />
      <Column
        mobile="12"
        tablet="12"
        desktop="7.2"
        style={{ display: 'flex', borderTop: '1px solid #7b7b7b61', justifyContent: 'flex-start' }}
      >
        <TeamColumn mobile="12" tablet="6" desktop="6">
          {TEAM.map((member, idx) => (
            <TeamCard key={idx} {...member} />
          ))}
        </TeamColumn>
      </Column>
      {/* </Column> */}
    </MainBar>
  </Wrapper>
);
