import React from 'react';
import { Column, Sidebar, TextBox } from 'components/common';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { Lead, MainBar } from '../../common';
import { Wrapper, DescriptionContainer } from './styles';

export const Everything = () => {
	const intl = useIntl();
	const x = intl.formatMessage({ id: 'what.categories' }).split('|');

	return (
		<Wrapper id="everything">
			<Sidebar label="menu.what" />
			<MainBar>
				<TextBox title="what.subtitle" subtitle="what.lead" lead="what.description" />

				<Column mobile="12" tablet="12" desktop="7.2">
					<DescriptionContainer>
						<div>
							{x.map((str, idx) => (
								<div key={idx}>
									<Lead key={idx}>{str}</Lead>
									<br />
								</div>
							))}
						</div>
					</DescriptionContainer>
				</Column>
			</MainBar>
		</Wrapper>
	);
};
