import React from 'react';

import stq from 'assets/clients/Stq.png';
import petninho from 'assets/clients/Petninho.png';
import prosaber from 'assets/clients/logo-prosaber.png';
import marilia from 'assets/clients/Marilia_dengue.png';
import hortolandia from 'assets/clients/Hortolandia.png';
import recicla from 'assets/clients/Recicla.png';
import alerta from 'assets/clients/Alerta.png';
import flash from 'assets/clients/Flash_tattoo.png';

import { Column, Sidebar, TextBox, MainBar } from '../../common';
import { Wrapper, TeamColumn } from '../Team/styles';
import { ProjectCard } from './ProjectCard';

const PROJECTS = [
  {
    name: 'Hortolândia 28 anos',
    role: 'Website & Mobile',
    bio:
      'Hotsite dinâmico com imagens comparativas dos estágios de evolução dos espaços e dos equipamentos públicos do município.',
    image: hortolandia,
  },
  {
    name: 'PetNinho',
    role: 'Website & Mobile',
    bio: 'Site para petshop de Curitiba, com agendamento via chat. Seleção Best CSS 2019.',
    image: petninho,
  },
  {
    name: 'PróSaber',
    role: 'Website & Mobile',
    bio:
      'Redesenho, atualização e programação do site de ONG promotora de atividades lúdicas e educativas para jovens e crianças do Paraisópolis. ',
    image: prosaber,
  },
  {
    name: 'Marília sem Dengue',
    role: 'Website & Mobile',
    bio:
      'Hotsite com mapa de focos, agenda de mobilização e mutirões, dicas e dúvidas, serviços de saúde disponíveis, quadro com números.',
    image: marilia,
  },
  {
    name: 'STQ',
    role: 'Website & Mobile',
    bio: 'Site para agência de publicidade e propaganda com base em Santo André e atuação nacional.',
    image: stq,
  },
  {
    name: 'ReciclaApp',
    role: 'App iOS/Android',
    bio: 'APP com prestação de serviço, informações e game sobre separação, coleta e reciclagem.  ',
    image: recicla,
  },
  {
    name: 'Alerta ABC',
    role: 'App iOS/Android',
    bio:
      'APP com informações sobre clima, interatividade, monitoramento, notificação e localização de vias alagadas ou bloqueadas. (desenvolvido em parceria)',
    image: alerta,
  },
  {
    name: 'Flash Tattoo',
    role: 'App iOS/Android',
    bio:
      'APP destinado a estúdios e tatuadores com galeria de tattoos, realidade aumentada, reserva, agenda e pagamento de serviços.',
    image: flash,
  },
];

export const Projects = () => (
  <Wrapper id="projects">
    <Sidebar label="menu.did" />
    <MainBar>
      <TextBox reverse title="menu.did" />
      <Column
        mobile="12"
        tablet="12"
        desktop="7.2"
        style={{ display: 'flex', borderTop: '1px solid #7b7b7b61', justifyContent: 'flex-start' }}
      >
        <TeamColumn mobile="12" tablet="6" desktop="6">
          {PROJECTS.map((member, idx) => (
            <ProjectCard key={idx} idx={idx} {...member} />
          ))}
        </TeamColumn>
      </Column>
    </MainBar>
  </Wrapper>
);
