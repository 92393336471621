import React from 'react';
import { Row, Column, Sidebar } from 'components/common';
import contact from 'assets/icons/brand.svg';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';
import { Details, Thumbnail, TitleContainer, SpecialLink, ContactLinks } from './styles';
import ContactForm from './ContactForm';
import { Lead, Title, MainBar } from '../../common';
import { SColumn } from './ContactForm/styles';

export const Contact = () => {
  const intl = useIntl();

  return (
    <Row style={{ color: 'white' }} id="contact">
      <Sidebar label="menu.want" />
      <MainBar>
        <Column
          mobile="12"
          tablet="12"
          desktop="4.8"
          style={{
            borderRight: '1px solid #7b7b7b61',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            borderTop: '1px solid #7b7b7b61',
          }}
        >
          <Details>
            <TitleContainer>
              <Title>
                <FormattedMessage id="contact.leave_a_message" />
              </Title>
            </TitleContainer>
            {/* @ts-ignore */}
            <ContactForm intl={intl} />
          </Details>
        </Column>
        <SColumn mobile="12" tablet="12" desktop="7.2" style={{ borderLeft: '1px solid #7b7b7b61' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <Thumbnail>
                <img src={contact} alt="Multiverso ID" />
              </Thumbnail>
              <br />
              <address>
                <Lead>Rua Dr. Eduardo Monteiro, 164</Lead>
                <Lead>Jardim Bela Vista, Santo André, SP</Lead>
                <br />
                <Lead>CEP: 09041-300</Lead>
              </address>
            </div>

            <ContactLinks>
              <Lead variant="white">
                <FormattedMessage id="contact.new_business" />
              </Lead>
              <br />
              <a href="tel:+5511-2337-2343">
                <SpecialLink>+55 11 2337.2343</SpecialLink>
              </a>
              <a href="mailto:contato@multiversoid.com">
                <SpecialLink>contato@multiversoid.com</SpecialLink>
              </a>
            </ContactLinks>
          </div>
        </SColumn>
      </MainBar>
    </Row>
  );
};
