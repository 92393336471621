import React from 'react';
import { Layout, SEO } from 'components/common';
import { Everything, Contact, Discovery, Hero, Team } from 'components/landing';
import { useIntl } from 'gatsby-plugin-intl';
import { Projects } from '../components/landing';

const IndexPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'title' })}
        keywords={[`gatsby`, `application`, `react`]}
      />
      <Hero />
      <Everything />
      <Discovery />
      <Team />
      <Projects />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
