import styled from 'styled-components';
import { Column } from '../../../common';

export const Error = styled.span`
  color: #ff4136;
`;

export const Center = styled.div`
  text-align: left;

  h4 {
    font-weight: normal;
  }
`;

export const InputField = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

export const AlignEnd = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  h4 {
    font-weight: normal;
  }
`;

export const SColumn = styled(Column)`
  height: 100%;
  padding: 110px 120px 0 120px;
  display: flex;
  border-top: 1px solid #7b7b7b61;

  @media (max-width: 960px) {
    padding: 60px 29px 0px
  }
`