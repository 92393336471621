import styled from 'styled-components';
import { Row, Column } from '../../common';
import overlayIllustration from 'assets/images/moon.png';

export const Wrapper = styled(Row)`
  background-size: cover;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-color: white;

  background-image: url(${overlayIllustration});

  min-height: calc(100vh - 140px);

  display: flex;
  color: #1b1010;

  @media (max-width: 960px) {
    box-sizing: border-box;
    background-size: 676px;
    background-position: -277px top;
  }
`;

export const CardWrapper = styled.div`
  padding-bottom: 120px;
  padding-left: 120px;

  @media (max-width: 1400px) {

  }

  @media (max-width: 1260px) {

  }

  @media (max-width: 960px) {
    padding: 32px;
  }
`;

export const TeamColumn = styled(Column)`
  padding-top: 125px;

  @media (max-width: 1400px) {

  }

  @media (max-width: 1260px) {

  }

  @media (max-width: 960px) {
    padding-top: 65px;
  }
`;
