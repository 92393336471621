import React from 'react';

import { Sidebar, TextBox, MainBar } from '../../common';

import { Wrapper, SideColumn } from './styles';

export const Discovery = () => {
  return (
    <Wrapper id="discovery">
      <Sidebar label="menu.how" />
      <MainBar>
        <TextBox
          title="how.subtitle"
          subtitle="how.lead"
          lead="how.description"
        />
        <SideColumn mobile="0" tablet="0" desktop="7.2" />
      </MainBar>
    </Wrapper >
  );
};
