import styled from 'styled-components';
import { Title } from '../../common';

export const Wrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;
  padding: 2rem 6rem;

  @media (max-width: 1400px) {
    padding: 2rem;
  }

  @media (max-width: 1260px) {
    padding: 2rem;
  }

  @media (max-width: 960px) {
    padding: unset;
    width: 100%;
    order: 1;
    padding: 2rem;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: #212121;
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: #707070;
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 220px;
    margin-bottom: 2rem;
  }

  img {
    width: 220px;
  }
`;


export const TitleContainer = styled.div`
  height: 180px;
  display: flex;
  align-items: flex-end;
  // justify-content: center;
  padding: 0 0 20px 0;
  flex: 1;

  h1 {
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: #212121;
    max-width: 225px;
  }
`


export const SpecialLink = styled(Title)`
  font-size: 25px;

  @media (max-width: 960px) {
    font-size: 20px;
  }
`;

export const ContactLinks = styled(Title)`
  margin-top: 150px;

  @media (max-width: 1400px) {
  }

  @media (max-width: 1260px) {
  }

  @media (max-width: 960px) {
    margin-top: 75px;
  }
`;
