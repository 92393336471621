import React from 'react';
import spaceman from 'assets/icons/id.svg';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { Lead, H4, H5, Avatar } from '../../common';
import { CardWrapper } from '../Team/styles';

type TeamProps = {
  name: string;
  role: string;
  bio: string;
  image: string;
  idx: number;
};

export const ProjectCard = ({ name, role, bio, image, idx }: TeamProps) => (
  <CardWrapper>
    <Avatar url={image} special={idx === 2} />
    <div>
      <H4>
        <img src={spaceman} alt="Multiverso ID" style={{ marginRight: 8 }} />
        {name}
      </H4>
      <H5>
        <FormattedMessage id={role} />
      </H5>
      <Lead>
        <FormattedMessage id={bio} />
      </Lead>
    </div>
  </CardWrapper>
);
